<!--
 * @Author: CL
 * @Date: 2021-06-28 20:44:33
 * @LastEditTime: 2021-07-21 21:51:42
 * @Description: 添加博客的模态框
-->

<template>
  <div class="addblogmodel-contain">
    <div class="main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="文章标题" prop="title">
          <el-input clearable class="cl-dialog" v-model="ruleForm.title" placeholder="必填，请输入文章标题"></el-input>
        </el-form-item>
        <el-form-item label="文章标签" prop="tags">
          <el-input clearable class="cl-dialog" v-model="ruleForm.tags" placeholder="必填,请输入文章标签请以逗号隔开"></el-input>
        </el-form-item>
        <el-form-item label="文章创建时间" prop="createTime">
           <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="ruleForm.createTime" style="width: 100%;"></el-date-picker>
        </el-form-item>
        <el-form-item label="文章配图" prop="picture">
          <el-upload
            :action="uploadURL"
            :headers="headers"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :on-exceed="handleExceed"
            :limit="1"
            :with-credentials="true"
            ref="upload"
            name="img"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        <el-form-item label="文章内容" prop="content" class="content">
          <mavon-editor 
            class="markdown-wrap"
            ref="md" 
            v-model="ruleForm.content"
            @imgAdd="handleImgAdd"
            @imgDel="handleImgDel"
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="footer cl-dialog-footer">
      <el-button class="my-button" @click="closeModel">取消</el-button>
      <el-button class="my-button" type="primary" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import { addBlog } from '@/api/blog';
import { mapState } from 'vuex';
import { uploadFile } from '@/api/file';

export default {
  data(){
    return {
      ruleForm: {
        title: '',         //文章标题
        tags:  '',         //文章标签
        content: '',       //文章内容
        views: 0,          //观看次数
        likes: 0,          //点赞次数
        createTime: '',    //项目创建时间
        picture: '',       //缩略图
      },

      dialogImageUrl: '',
      dialogVisible: false,

      rules: {
        title: [
          { required: true, message: '文章标题不能为空', trigger: 'blur' },
        ],
        tags: [
          { required: true, message: '文章标签不能为空', trigger: 'blur' },
        ],
        createTime: [
          { required: true, message: '创建时间不能为空', trigger: 'change' },
        ],
        picture: [
          { required: true, message: '文章配图不能为空', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '文章内容不能为空', trigger: 'blur' },
        ],
      },
    }
  },

  methods: {
    close(data){
      this.$emit('closeAddBlogModel', false, data);
      this.$refs['ruleForm'].resetFields();
      this.$refs['upload'].clearFiles();
    },

    closeModel(){
      this.close();
    },

    save(){
      this.$refs['ruleForm'].validate(async (valid) => {
        if (!valid) {
          return;
        }
        try{
          const res = await addBlog(this.ruleForm);
          if(res.code == 200){
            this.$showMessage({
              type: 'success',
              message: '添加成功'
            })
            this.close('refresh');
          }
        }catch(err){
          console.log(err);
        }
      });
    },

    /**
     * md上，上传图片
     */
    async handleImgAdd(pos, $file){
      try{
        const forData = new FormData();
        forData.append('img', $file);
        const res = await uploadFile(forData);
        if(res.code == 200){
          this.$refs.md.$img2Url(pos, this.baseURL + res.data);
        }
      }catch(err){
        console.log(err);
      }
    },

    /**
     * md上，删除图片
     */
    handleImgDel(){

    },

    /**
     * 移除图片
     */
    handleRemove() {
      this.$set(this.ruleForm, 'picture', '');
    },

    /**
     * 上传成功的回调
     */
    handleSuccess(res){
      console.log(res);
      if(res.code == 200){
        this.$set(this.ruleForm, 'picture', res.data);
      }
    },

    /**
     * 限制上传的个数
     */
    handleExceed(){
      this.$showMessage({
        type: 'warning',
        message: '只能上传一张图片哦'
      })
    },

    /**
     * 预览图片
     */
    handlePictureCardPreview(file){
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  },

  computed: {
    ...mapState(['uploadURL', 'headers', 'baseURL'])
  }
}
</script>

<style lang="less" scoped>
.addblogmodel-contain{
  
  .markdown-wrap{
    height: 300px;
  }
}
</style>
