/*
 * @Author: CL
 * @Date: 2021-07-13 12:54:23
 * @LastEditTime: 2021-07-13 12:58:51
 * @Description: 文件上传api
 */

import request from './request';

export function uploadFile(data) {
  return request({
    url: '/api/upload',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
