<!--
 * @Author: CL
 * @Date: 2021-06-23 11:17:47
 * @LastEditTime: 2021-07-22 12:53:27
 * @Description: 后台管理
-->

<template>
  <div class="articleback-contain cl-wrap">
    <div class="cl-table-header">
      <el-row style="width: 100%" :gutter="20">
        <el-col :span="6">
          <el-input class="cl-input" v-model="searchForm.title" placeholder="请输入项目名称"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="medium" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="cl-table-main" v-loading="loading">
      <div class="main-top">
        <div>文章信息</div>
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="openAddBlogModel">添加</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ textAlign: 'center' }"
        class="table-wrap"
        max-height="580"
      >
        <el-table-column
          prop="title"
          label="文章标题"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="views"
          label="观看次数"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="likes"
          label="点赞次数"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="handleEdit(scope.row)">修改</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <Pagination 
          :page="page" 
          :size="size" 
          :total="total" 
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>

    <Dialog 
      :isShow="addBlogModel" 
      title="添加文章"
      :width="90" 
    >
      <addBlog @closeAddBlogModel="closeAddBlogModel" />
    </Dialog>

    <Dialog 
      :isShow="editBlogModel" 
      title="修改文章"
      :width="90" 
    >
      <editBlog 
        :editInfo="editInfo"
        :key="key"
        @closeEditBlogModel="closeEditBlogModel" 
      />
    </Dialog>
  </div>
</template>

<script>
import { getBlog, delBlog } from '@/api/blog';
import Pagination from '@/components/Pagination';
import addBlog from './components/addBlogModel.vue';
import Dialog from '@/components/Dialog';
import editBlog from './components/editBlogModel.vue';

export default {
  data(){
    return {
      searchForm: {
        title: '',    //文章的标题
      },
      page: 1,
      size: 10,
      total: 0,
      tableData: [],
      loading: true,

      addBlogModel: false,
      editBlogModel: false,
      editInfo: {},
      key: ''
    }
  },

  components: {
    Pagination,
    addBlog,
    Dialog,
    editBlog
  },

  methods: {
    /**
     * 搜索
     */
    handleSearch(){

    },

    /**
     * 打开添加文章的模态框
     */
    openAddBlogModel(){
      this.addBlogModel = true;
    },

    /**
     * 关闭添加文章的模态框
     */
    closeAddBlogModel(flag, isRefresh){
      this.addBlogModel = flag;
      if(isRefresh == 'refresh'){
        this.queryBlog();
      }
    },

    /**
     * 修改
     */
    handleEdit(data){
      this.editInfo = data;
      this.editBlogModel = true;
    },

    /**
     * 关闭修改的模态框
     */
    closeEditBlogModel(flag, isRefresh){
      this.editBlogModel = flag;
      this.key = new Date().getTime();
      if(isRefresh == 'refresh'){
        this.queryBlog();
      }
    },

    /**
     * 删除
     */
    handleDelete(data){
      const { id } = data;
      this.$utils.confirm('您确定要删除该文章吗?').then(async () => {
        const res = await delBlog(id);
        if(res.code == 200){
          this.$showMessage({
            type: 'success',
            message: '删除成功!'
          });
          this.queryBlog();
        }
      }).catch(err => {
        //点击了取消
        this.$showMessage({
          type: 'info',
          message: '已取消删除'
        }); 
      })
    },

    /**
     * size发生改变
     */
    handleSizeChange(val){
      this.size = val;
      this.queryBlog();
    },

    /**
     * page发生改变
     */
    handleCurrentChange(val){
      this.page = val;
      this.queryBlog();
    },

    /**
     * 分页查询文章
     */
    async queryBlog(){
      this.loading = true;
      try{
        const { page, size } = this;
        const res = await getBlog(page, size);
        this.loading = false;
        if(res.code == 200){
          const { count, rows } = res.data;
          this.total = count;
          this.tableData = rows;
        }
      }catch(err){
        console.log(err);
      }
    }
  },

  created(){
    this.queryBlog();
  }
}
</script>

<style>

</style>